body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.title{
  font-family: 'Bungee', cursive;
  width: 100%;
  padding: 0px;
  text-align: center;
  font-size:25px;
  background-color: rgb(174, 91, 174);
  color: white;
}

.titleContainer{
  font-family: 'Bungee', cursive;
  width: 100%;
  padding: 0px;
  background-color: rgb(174, 91, 174);
  color: white;
  display: flex; /* set display property to flex */
  justify-content: space-between;
}

.centeredContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.centeredContainerPastLectureMobile{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-left: 10vw;
}
.centeredContainerPastLecture{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-left: 15vw;
}
.signInContainer{
  margin-top: 75%;
  width:300px ;
  background-color: rgb(2, 165, 219);
  border-radius: 25px;
  box-shadow: 0 0 3px black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.signInText{
  font-family: 'Bungee', cursive;
  font-size:1.5rem;
  text-align: center;
  color: white;
  background-color: rgb(3, 135, 179);
  width:100%;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.signInButton {
  font-family: 'Bungee', cursive;
  font-size: 0,9rem;
  margin-left: 1%;
  width:60%;
  height: 50px;
  position: center;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  cursor: pointer;
  background: rgb(76, 198, 239);
  border: 2px solid rgb(53, 154, 187);
  border-radius: 45px;
  color: white;
  overflow: hidden;
  transition: all 0.2s ease-in;
  z-index: 1;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align:center;

}

.homeSignIn {
  font-family: 'Bungee', cursive;
  font-size: 0.9rem;
  width:80%;
  height: 50px;
  position: center;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  cursor: pointer;
  background-color: #bf82e8;
  border: 2px solid #aa6ed9;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  color: #ffffff;
  overflow: hidden;
  transition: all 0.2s ease-in;
  z-index: 1;
  width: 100%;
  text-align:center;

}

.signInButton:hover .homeSignIn:hover {
  transform: scale(1.05);
}

.eButtonContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
}

.eButtonContainerMobile{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
}

.button {
  font-family: 'Bungee', cursive;
  font-size: 0,9rem;
  margin-left: 1%;
  width:12%;
  height: 50px;
  position: relative;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  cursor: pointer;
  background: rgb(76, 198, 239);
  border: 2px solid rgb(53, 154, 187);
  border-radius: 45px;
  color: white;
  overflow: hidden;
  box-shadow: 0 2px 4px darkgray;
  transition: all 0.2s ease-in;
  z-index: 1;
  margin-top: 20px;
  margin-bottom: 20px;
}

.mobileButton {
  font-family: 'Bungee', cursive;
  font-size: 0,9rem;
  width:50%;
  height: 50px;
  position: relative;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  cursor: pointer;
  background: rgb(76, 198, 239);
  border: 2px solid rgb(53, 154, 187);
  border-radius: 45px;
  color: white;
  overflow: hidden;
  box-shadow: 0 2px 4px darkgray;
  transition: all 0.2s ease-in;
  z-index: 1;
  margin-top: 20px;
  margin-bottom: 20px;
}

.button .circle {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  background-image: radial-gradient(circle closest-side, white, rgb(53, 154, 187));
  width: 1px;
  height: 1px;
  z-index: 3;
  -webkit-animation-name: ripple;
          animation-name: ripple;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
}

.mobileButton .circle {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  background-image: radial-gradient(circle closest-side, white, rgb(53, 154, 187));
  width: 1px;
  height: 1px;
  z-index: 3;
  -webkit-animation-name: ripple;
          animation-name: ripple;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
}

.button:hover {
  transform: scale(1.03);
  box-shadow: 0 6px 4px darkgray;
}

.mobileButton:hover {
  transform: scale(1.03);
  box-shadow: 0 6px 4px darkgray;
}

.button-cooldown{
  font-family: 'Bungee', cursive;
  font-size: 0,9rem;
  margin-left: 1%;
  width:12%;
  height: 50px;
  position: relative;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  cursor:not-allowed;
  background: rgb(221, 147, 147); /* background color to change */
  border: 2px solid rgb(255, 0, 0); /* border color to change */
  border-radius: 45px;
  color: white;
  overflow: hidden;
  box-shadow: 0 2px 4px darkgray;
  transition: all 0.2s ease-in;
  z-index: 1;
  margin-top: 20px;
  margin-bottom: 20px;
}

.button-cooldownMobile{
  font-family: 'Bungee', cursive;
  font-size: 0,9rem;
  margin-left: 1%;
  width:50%;
  height: 50px;
  position: relative;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  cursor:not-allowed;
  background: rgb(221, 147, 147); /* background color to change */
  border: 2px solid rgb(255, 0, 0); /* border color to change */
  border-radius: 45px;
  color: white;
  overflow: hidden;
  box-shadow: 0 2px 4px darkgray;
  transition: all 0.2s ease-in;
  z-index: 1;
  margin-top: 20px;
  margin-bottom: 20px;
}


.button-cooldown .circle {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  /* First color is the circle start, second is to the sides */
  background-image: radial-gradient(circle closest-side, rgb(255, 255, 255), rgb(255, 0, 0));
  width: 1px;
  height: 1px;
  z-index: 3;
  -webkit-animation-name: ripple;
          animation-name: ripple;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
} 

.button-cooldownMobile .circle {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  /* First color is the circle start, second is to the sides */
  background-image: radial-gradient(circle closest-side, rgb(255, 255, 255), rgb(255, 0, 0));
  width: 1px;
  height: 1px;
  z-index: 3;
  -webkit-animation-name: ripple;
          animation-name: ripple;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
} 

.button-cooldown:hover {
  transform: none;
  box-shadow: none;
}

.button-cooldownMobile:hover {
  transform: none;
  box-shadow: none;
}

@-webkit-keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  75% {
    transform: scale(450, 450);
    opacity: 0.75;
  }
  100% {
    transform: scale(700, 700);
    opacity: 0;
  }
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  75% {
    transform: scale(450, 450);
    opacity: 0.75;
  }
  100% {
    transform: scale(700, 700);
    opacity: 0;
  }
}

.padded{
  margin-left: 10%;
}

.classContainer{
  width: 70%;
  height: 500px;
}

.splash {
  font-family: 'Trebuchet MS', cursive;
  overflow: hidden;
  padding: 30px;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
  background-color: #be6ed9;
  color: white;
  border-radius: 8px;
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.splashMobile {
  font-family: 'Trebuchet MS', cursive;
  overflow: hidden;
  padding: 30px;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
  background-color: #be6ed9;
  color: white;
  border-radius: 8px;
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.classContainer{
  width: 70%;
  height: 70%;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(174, 91, 174);
  overflow-y: scroll;
  flex-grow: 1;
}

.noClassMessage{
  font-family: 'Bungee', cursive;
  font-size: 1.5rem;
  text-align: center;
  color: white;
  margin-top: 5%;
  margin-bottom: 5%;
}

.joinClassButton{
  font-family: 'Bungee', cursive;
  font-size: 1rem;
  margin-left: 70%;
  width:15%;
  position: relative;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  cursor: pointer;
  background: rgb(76, 198, 239);
  border: 2px solid rgb(53, 154, 187);
  border-radius: 45px;
  color: white;
  overflow: hidden;
  transition: all 0.2s ease-in;
  z-index: 1;
  margin-top: 0.5%;
  margin-right: 0.5%;
  position: absolute;
  top: 0;
  right: 0;
}

.joinClassButton:hover {
  transform: scale(1.05);
}

.classNameForm{
  width:20%;
  height:50%;
  background-color: rgb(2, 165, 219);
  border-radius: 25px;
  box-shadow: 0 0 3px black;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 25%;
  left: 40%;
  position: absolute;
}

.classNameInput{
  margin-left: 1%;
  flex: 75%;
  margin: 10px;
  width:90%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  border: none;
  padding: 15px;
  border-radius: 6.5px;
  position: relative;
}

.submitClassNameButton{
  font-family: 'Bungee', cursive;
  font-size: 0,9rem;
  margin-left: 1%;
  flex: 25%;
  width:60%;
  height:15%;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  cursor: pointer;
  background: rgb(76, 198, 239);
  border: 2px solid rgb(53, 154, 187);
  border-radius: 15px;
  color: white;
  overflow: hidden;
  transition: all 0.2s ease-in;
  z-index: 1;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align:center;
  vertical-align: center;
  display: center;
  line-height: 260%;
}

ul{
  width:85%;
  height:100%;
}

.teacherClassCardContainer {
  margin-top: 10%;
  margin-bottom: 10%;
  width: 100%;
  height: 15%;
  display: flex;
  align-items: center;
  border: 1px solid black;
  background-color: rgb(2, 165, 219);
  flex-grow: 1;
}

.teacherClassCard{
  width: 100%;
  height: 100%;
  font-family: 'Bungee', cursive;
  font-size: 1.9rem;
}

.centeredName {
  text-align: center;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.deleteButton{
  background: transparent;
  border:0;
  float: left;
  color: rgb(83, 81, 81);
}

.startClassButton{
  margin-right: 3%;
  width: 20%;
  font-family: 'Bungee', cursive;
  font-size: 1rem;
  position: relative;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  cursor: pointer;
  background: rgb(76, 198, 239);
  border: 2px solid rgb(53, 154, 187);
  color: white;
  overflow: hidden;
  transition: all 0.2s ease-in;
}

.startClassButton:hover {
  transform: scale(1.05);
}

.lectureNameForm{
    height: 4rem;
    background-color: rgb(2, 165, 219);
    border-radius: 5px;
    box-shadow: 0 0 3px black;
    display: flex;
    width: 97.5%;
    padding: 10px;
    align-items: center;
}

.submitLectureNameButton{
  font-family: 'Bungee', cursive;
  font-size: 0,9rem;
  cursor: pointer;
  background: rgb(76, 198, 239);
  border: 2px solid rgb(53, 154, 187);
  border-radius: 45px;
  color: white;
  overflow: hidden;
  transition: all 0.2s ease-in;
  z-index: 1;
  width: 80%;
  text-align:center;
  vertical-align: center;
  display: center;
  line-height: 260%;
}

.submitLectureNameButtonMobile{
  font-family: 'Bungee', cursive;
  font-size: 0.8rem;
  cursor: pointer;
  background: rgb(76, 198, 239);
  border: 2px solid rgb(53, 154, 187);
  border-radius: 45px;
  color: white;
  overflow: hidden;
  transition: all 0.2s ease-in;
  z-index: 1;
  width: 80%;
  text-align:center;
  vertical-align: center;
  display: center;
  line-height: 260%;
}

.startLectureButton{
    font-family: 'Bungee', cursive;
    font-size: 0,9rem;
    cursor: pointer;
    background: rgb(76, 198, 239);
    border: 2px solid rgb(53, 154, 187);
    border-radius: 45px;
    color: white;
    overflow: hidden;
    transition: all 0.2s ease-in;
    z-index: 1;
    width: 80%;
    text-align:center;
    vertical-align: center;
    display: center;
}

.classCode{
  font-family: 'Bungee', cursive;
  font-size: 11.5rem;
  text-align: center;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
}

.classCodeMobile{
  font-family: 'Bungee', cursive;
  font-size: 3.5rem;
  text-align: center;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
}

.classCodeButton{
  font-family: 'Bungee', cursive;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  background: rgb(76, 198, 239);
  border: 2px solid rgb(53, 154, 187);
  border-radius: 25px;
  color: white;
  width: 100%;
  height: 4rem;
  margin-bottom: 2;
}

.endClassButton{
  font-family: 'Bungee', cursive;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 1px;
  background: rgb(76, 198, 239);
  border: 2px solid rgb(53, 154, 187);
  border-radius: 25px;
  color: white;
  width: 50%;
  height: 3rem;
  margin-left: 25vw;
  margin-bottom: 2vh;
}

.endClassMobile{
  font-family: 'Bungee', cursive;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  background: rgb(76, 198, 239);
  border: 2px solid rgb(53, 154, 187);
  border-radius: 25px;
  color: white;
  width: 100%;
  height: 4rem;
  margin-top: 50vw;
}

.waitMessage{
  font-family: 'Bungee', cursive;
  font-size: 3rem;
  text-align: center;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
}

.waitMessageMobile{
  font-family: 'Bungee', cursive;
  font-size: 3rem;
  text-align: center;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;

}

.lectureList{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  padding: 10px;
  width: 90%;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
  background-color: #d198f3;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 90vh;
}

.listHolder{
  overflow-y: scroll;
  scroll-behavior: smooth;
  width: 97.5%;
}

.lectureListItem {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  border: 2px solid rgb(53, 154, 187);
  transition: background-color 0.3s, color 0.3s;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  color: white;
  position: relative;
  font-size: 1.8rem;
  font-family: 'Bungee', cursive;
  font-size: 1.8rem;
}

.lectureListItemText{
  font-family: 'Bungee', cursive;
  font-size: 1.8rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.lectureText{
  font-family: 'Bungee', cursive;
  font-size: 1.8rem;
  text-align: center;
}

.lectureListItem:hover {
  background-color: rgb(53, 154, 187);
}

.bigContainer{
  width: 100%;
  align-items: center;
}
/*width: 100%;
  align-items: center;*/
.graphContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.graphButtonContainer{
  display: flex;
  width: 45%;
  justify-content: space-between;
  margin-left: 2%;
}

.solidArrowButton {
  font-family: 'Bungee', cursive;
  font-size: 0,9rem;
  margin-left: 1%;
  width:20%;
  height: 50px;
  position: relative;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  cursor: pointer;
  background: rgb(76, 198, 239);
  border: 2px solid rgb(53, 154, 187);
  border-radius: 45px;
  color: white;
  overflow: hidden;
  box-shadow: 0 2px 4px darkgray;
  transition: all 0.2s ease-in;
  z-index: 1;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clearArrowButton{
    font-family: 'Bungee', cursive;
    font-size: 0,9rem;
    margin-left: 1%;
    width:20%;
    height: 50px;
    position: relative;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    background: rgb(76, 198, 239);
    border: 2px solid rgb(53, 154, 187);
    border-radius: 45px;
    color: white;
    overflow: hidden;
    box-shadow: 0 2px 4px darkgray;
    transition: all 0.2s ease-in;
    z-index: 1;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 50%;
}

.pastLectureContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.dataContainer{
  position: relative;
  background-color: rgb(76, 198, 239);
  width: 30%;
  height: 100%;
  border-radius: 5px;
  box-shadow: black 0px 0px 5px;
  word-wrap: break-word;
  margin-right: 5%;
  text-align: center;
  overflow-wrap: break-word!important;
}

.dataContainerMobile{
  position: relative;
  background-color: rgb(76, 198, 239);
  width: 80%;
  height: 100%;
  border-radius: 5px;
  box-shadow: black 0px 0px 5px;
  word-wrap: break-word;
  margin-right: 5%;
  text-align: center;
  overflow-wrap: break-word!important;
}


.dataItem{
  font-family: 'Bungee', cursive;
  padding: 10px;
  position: relative;
  display: flex;
  display: inline-block;
}

.dataTitle{
  font-family: 'Bungee', cursive;
  padding: 10px;
  position: center;
  font-size:x-large;
  color: rgb(83, 81, 81);
}

.dataDate{
  font-family: 'Bungee', cursive;
  padding: 10px;
  position: relative;
  font-size:large;
  color: rgb(83, 81, 81);
}

.dataholder{
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.increase{
  color: green;
  display: inline-block;
}

.decrease{
  color: red;
  display: inline-block;
}

.back{
  left:0%;
  position: absolute;
}

.mobileSignInContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mobileSignIn{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}






.banter-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 72px;
  height: 72px;
  margin-left: -36px;
  margin-top: 64px;
}

.banter-loader__box {
  float: left;
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.banter-loader__box:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
}

.banter-loader__box:nth-child(3n) {
  margin-right: 0;
  margin-bottom: 6px;
}

.banter-loader__box:nth-child(1):before, .banter-loader__box:nth-child(4):before {
  margin-left: 26px;
}

.banter-loader__box:nth-child(3):before {
  margin-top: 52px;
}

.banter-loader__box:last-child {
  margin-bottom: 0;
}

@keyframes moveBox-1 {
  9.0909090909% {
    transform: translate(-26px, 0);
  }

  18.1818181818% {
    transform: translate(0px, 0);
  }

  27.2727272727% {
    transform: translate(0px, 0);
  }

  36.3636363636% {
    transform: translate(26px, 0);
  }

  45.4545454545% {
    transform: translate(26px, 26px);
  }

  54.5454545455% {
    transform: translate(26px, 26px);
  }

  63.6363636364% {
    transform: translate(26px, 26px);
  }

  72.7272727273% {
    transform: translate(26px, 0px);
  }

  81.8181818182% {
    transform: translate(0px, 0px);
  }

  90.9090909091% {
    transform: translate(-26px, 0px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.banter-loader__box:nth-child(1) {
  animation: moveBox-1 4s infinite;
}

@keyframes moveBox-2 {
  9.0909090909% {
    transform: translate(0, 0);
  }

  18.1818181818% {
    transform: translate(26px, 0);
  }

  27.2727272727% {
    transform: translate(0px, 0);
  }

  36.3636363636% {
    transform: translate(26px, 0);
  }

  45.4545454545% {
    transform: translate(26px, 26px);
  }

  54.5454545455% {
    transform: translate(26px, 26px);
  }

  63.6363636364% {
    transform: translate(26px, 26px);
  }

  72.7272727273% {
    transform: translate(26px, 26px);
  }

  81.8181818182% {
    transform: translate(0px, 26px);
  }

  90.9090909091% {
    transform: translate(0px, 26px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.banter-loader__box:nth-child(2) {
  animation: moveBox-2 4s infinite;
}

@keyframes moveBox-3 {
  9.0909090909% {
    transform: translate(-26px, 0);
  }

  18.1818181818% {
    transform: translate(-26px, 0);
  }

  27.2727272727% {
    transform: translate(0px, 0);
  }

  36.3636363636% {
    transform: translate(-26px, 0);
  }

  45.4545454545% {
    transform: translate(-26px, 0);
  }

  54.5454545455% {
    transform: translate(-26px, 0);
  }

  63.6363636364% {
    transform: translate(-26px, 0);
  }

  72.7272727273% {
    transform: translate(-26px, 0);
  }

  81.8181818182% {
    transform: translate(-26px, -26px);
  }

  90.9090909091% {
    transform: translate(0px, -26px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.banter-loader__box:nth-child(3) {
  animation: moveBox-3 4s infinite;
}

@keyframes moveBox-4 {
  9.0909090909% {
    transform: translate(-26px, 0);
  }

  18.1818181818% {
    transform: translate(-26px, 0);
  }

  27.2727272727% {
    transform: translate(-26px, -26px);
  }

  36.3636363636% {
    transform: translate(0px, -26px);
  }

  45.4545454545% {
    transform: translate(0px, 0px);
  }

  54.5454545455% {
    transform: translate(0px, -26px);
  }

  63.6363636364% {
    transform: translate(0px, -26px);
  }

  72.7272727273% {
    transform: translate(0px, -26px);
  }

  81.8181818182% {
    transform: translate(-26px, -26px);
  }

  90.9090909091% {
    transform: translate(-26px, 0px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.banter-loader__box:nth-child(4) {
  animation: moveBox-4 4s infinite;
}

@keyframes moveBox-5 {
  9.0909090909% {
    transform: translate(0, 0);
  }

  18.1818181818% {
    transform: translate(0, 0);
  }

  27.2727272727% {
    transform: translate(0, 0);
  }

  36.3636363636% {
    transform: translate(26px, 0);
  }

  45.4545454545% {
    transform: translate(26px, 0);
  }

  54.5454545455% {
    transform: translate(26px, 0);
  }

  63.6363636364% {
    transform: translate(26px, 0);
  }

  72.7272727273% {
    transform: translate(26px, 0);
  }

  81.8181818182% {
    transform: translate(26px, -26px);
  }

  90.9090909091% {
    transform: translate(0px, -26px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.banter-loader__box:nth-child(5) {
  animation: moveBox-5 4s infinite;
}

@keyframes moveBox-6 {
  9.0909090909% {
    transform: translate(0, 0);
  }

  18.1818181818% {
    transform: translate(-26px, 0);
  }

  27.2727272727% {
    transform: translate(-26px, 0);
  }

  36.3636363636% {
    transform: translate(0px, 0);
  }

  45.4545454545% {
    transform: translate(0px, 0);
  }

  54.5454545455% {
    transform: translate(0px, 0);
  }

  63.6363636364% {
    transform: translate(0px, 0);
  }

  72.7272727273% {
    transform: translate(0px, 26px);
  }

  81.8181818182% {
    transform: translate(-26px, 26px);
  }

  90.9090909091% {
    transform: translate(-26px, 0px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.banter-loader__box:nth-child(6) {
  animation: moveBox-6 4s infinite;
}

@keyframes moveBox-7 {
  9.0909090909% {
    transform: translate(26px, 0);
  }

  18.1818181818% {
    transform: translate(26px, 0);
  }

  27.2727272727% {
    transform: translate(26px, 0);
  }

  36.3636363636% {
    transform: translate(0px, 0);
  }

  45.4545454545% {
    transform: translate(0px, -26px);
  }

  54.5454545455% {
    transform: translate(26px, -26px);
  }

  63.6363636364% {
    transform: translate(0px, -26px);
  }

  72.7272727273% {
    transform: translate(0px, -26px);
  }

  81.8181818182% {
    transform: translate(0px, 0px);
  }

  90.9090909091% {
    transform: translate(26px, 0px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.banter-loader__box:nth-child(7) {
  animation: moveBox-7 4s infinite;
}

@keyframes moveBox-8 {
  9.0909090909% {
    transform: translate(0, 0);
  }

  18.1818181818% {
    transform: translate(-26px, 0);
  }

  27.2727272727% {
    transform: translate(-26px, -26px);
  }

  36.3636363636% {
    transform: translate(0px, -26px);
  }

  45.4545454545% {
    transform: translate(0px, -26px);
  }

  54.5454545455% {
    transform: translate(0px, -26px);
  }

  63.6363636364% {
    transform: translate(0px, -26px);
  }

  72.7272727273% {
    transform: translate(0px, -26px);
  }

  81.8181818182% {
    transform: translate(26px, -26px);
  }

  90.9090909091% {
    transform: translate(26px, 0px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.banter-loader__box:nth-child(8) {
  animation: moveBox-8 4s infinite;
}

@keyframes moveBox-9 {
  9.0909090909% {
    transform: translate(-26px, 0);
  }

  18.1818181818% {
    transform: translate(-26px, 0);
  }

  27.2727272727% {
    transform: translate(0px, 0);
  }

  36.3636363636% {
    transform: translate(-26px, 0);
  }

  45.4545454545% {
    transform: translate(0px, 0);
  }

  54.5454545455% {
    transform: translate(0px, 0);
  }

  63.6363636364% {
    transform: translate(-26px, 0);
  }

  72.7272727273% {
    transform: translate(-26px, 0);
  }

  81.8181818182% {
    transform: translate(-52px, 0);
  }

  90.9090909091% {
    transform: translate(-26px, 0);
  }

  100% {
    transform: translate(0px, 0);
  }
}

.banter-loader__box:nth-child(9) {
  animation: moveBox-9 4s infinite;
}
